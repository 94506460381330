<template>
  <div id="source-usage-report">
    <a-spin :spinning="spinning" style="min-height: 400px">
      <template v-if="detail.name">
        <a-dropdown placement="bottomCenter" style="position: absolute; right: 0px; top: 24px">
          <a-button
            @click="e => e.preventDefault()"
            type="primary"
          >
            导出
          </a-button>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-button size="small" type="link" icon="project" @click="exportPdf" style="color: #575757;">导出页面</a-button>
            </a-menu-item>
            <a-menu-item>
              <a-button :loading="excelLoading" size="small" @click="exportExcel"  type="link" icon="table" style="color: #575757;">导出 Excel</a-button>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <div id="pdfDom">
          <header style="padding: 52px 80px 0">{{ detail.name }}</header>

          <!-- 基本信息 -->
          <div class="small-module-title">
            <a-icon type="profile"></a-icon>
            <span> 基本信息</span>
          </div>
          <div class="base-info-container">
            <div class="base-info">
              统计周期：{{ detail.datetime_from }}～{{ detail.datetime_to }}
            </div>
            <div class="base-info">资源数量：{{ detail.statistic.total }}</div>
            <div class="base-info" style="padding: 0">
              比较值 (阈值)：
              <span v-if="detail.cpu_range.length">
                <a-tag color="pink">
                  {{ detail.cpu_range[0] }}%&lt;CPU使用率&lt;{{ detail.cpu_range[1] }}%
                </a-tag>
              </span>
              <span v-if="detail.memory_range.length">
                <a-tag color="orange">
                  {{ detail.memory_range[0] }}%&lt;内存使用率&lt;{{ detail.memory_range[1] }}%
                </a-tag>
              </span>
            </div>
          </div>

          <!-- 使用率情况 -->
          <div class="small-module-title">
            <a-icon type="bar-chart"></a-icon>
            <span> 使用率情况</span>
          </div>
          <a-tabs type="card" v-if="detail.statistic['total']" v-model="activeTab">
            <a-tab-pane key="all" tab="全部">
              <template v-for="(data, title) in detail.statistic">
                <div
                  v-if="title !== 'total'"
                  :key="title"
                  style="margin: 16px 16px 0"
                >
                  <a-divider class="pdf-split-flag" orientation="left">
                    {{ $t(`source_usage_advice_type.${title}`) }}
                  </a-divider>
                  <div
                    :class="[
                      'alert-info-container',
                      'pdf-split-flag',
                      'alert-info-container_' + title
                    ]"
                  >
                    <div class="title">{{ data.title }}</div>
                    <div class="message">{{ data.description }}</div>
                  </div>
                  <source-usage-table
                    :dataSource="data.data"
                    :cpuRange="getRange(detail.cpu_range)"
                    :memoryRange="getRange(detail.memory_range)"
                    :adviceTitle="title === 'abnormal' ? '异常原因' : '优化建议'"
                    idFlag="overview"
                  ></source-usage-table>
                </div>
              </template>
            </a-tab-pane>
            <a-tab-pane v-for="item in tabList" :key="item.value" :tab="item.title">
              <div
                :class="[
                  'alert-info-container',
                  'alert-info-container_' + item.value
                ]"
              >
                <div class="title">{{ detail.statistic[item.value].title }}</div>
                <div class="message">
                  {{ detail.statistic[item.value].description }}
                </div>
              </div>
              <source-usage-table
                :dataSource="detail.statistic[item.value].data"
                :cpuRange="getRange(detail.cpu_range)"
                :memoryRange="getRange(detail.memory_range)"
                :adviceTitle="item.value === 'abnormal' ? '异常原因' : '优化建议'"
              ></source-usage-table>
            </a-tab-pane>
          </a-tabs>
          <div v-else class="pdf-split-flag" style="padding: 0 24px; color: rgba(0,0,0,.85)">暂无数据。</div>
        </div>
      </template>
      <div v-else style="height: 400px; position: relative; overflow: hidden;">
        <empty-component
          :imgUrl="require('@/assets/images/info_empty.png')"
          :body-style="{
            height: '220px',
            marginTop: '70px'
          }"
          :description-style="{
            marginTop: '-12px',
            marginRight: '8px',
            color: 'rgba(0,0,0,.55)'
          }"
          description="暂无数据"
        >
        </empty-component>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { getSourceUsageReport, exportSourceUsageReport } from '@/api/report'
import PdfLoader from '@/utils/htmlToPdf'
import SourceUsageTable from './modules/SourceUsageTable.vue'
import EmptyComponent from '@/components/EmptyComponent.vue'

export default {
  name: 'SourceUsageReportDetail',
  components: {
    SourceUsageTable,
    EmptyComponent
  },
  data () {
    return {
      spinning: false,
      excelLoading: false,
      activeTab: 'all',
      detail: {
        cpu_range: [],
        memory_range: [],
        statistic: {
          total: 0
        }
      },
      tabList: []
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.spinning = true
      getSourceUsageReport(this.$route.params.id).then(res => {
        this.detail = res.data
        if (this.detail.statistic) {
          this.getTabList(this.detail.statistic)
        }
      }).finally(() => {
        this.spinning = false
      })
    },
    getRange (range) {
      return [0, ...range, 100]
    },
    getTabList (data) {
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key) && key !== 'total') {
          this.tabList.push({
            value: key,
            title: this.$t(`source_usage_advice_type.${key}`)
          })
        }
      }
    },
    change (v) {},
    exportPdf () {
      this.spinning = true
      this.activeTab = 'all'
      this.$nextTick(() => {
        const ele = document.getElementById('pdfDom')
        const pdf = new PdfLoader(ele, this.detail.name, 'pdf-split-flag')
        pdf.outPdf().then(() => {
          this.spinning = false
        })
      })
    },
    exportExcel () {
      this.excelLoading = true
      exportSourceUsageReport({ id: this.detail.id }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res], { type: '.xlsx' }))
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.setAttribute('download', `${this.detail.name}.xlsx`)
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }).finally(() => {
        this.excelLoading = false
      })
    }
  }
}
</script>

<style lang="less">
#source-usage-report {
  background: #ffffff;
  border-radius: 8px;
  padding: 0 26px 22px 26px;
  position: relative;

  .outPdf-thead {
    height: 40px !important;
    th {
      padding: 0 16px;
      background: #fff !important;
      height: 40px !important;
      font-size: 14px !important;
      border: none !important;
      box-sizing: border-box !important;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
    }
  }

  header {
    font-size: 30px;
    font-weight: 500;
    color: #096dd9;
    line-height: 42px;
    text-align: center;
    margin-bottom: 48px;
  }

  .small-module-title {
    height: 35px;
    background: #e4f1ff;
    border-radius: 8px;
    color: #096dd9;
    padding: 0 16px;
    margin-bottom: 16px;

    i {
      font-size: 16px;
      height: 20px;
      vertical-align: sub;
    }
    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 37px;
      vertical-align: middle;
    }
  }

  .base-info-container {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    padding: 0 64px;
    margin: 32px 0 32px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .base-info {
      padding: 0 16px 0 0;
    }
  }

  .alert-info-container {
    padding: 14px 21px;
    border-radius: 8px;
    margin: 8px 0 24px 0;
    .title {
      font-size: 16px;
      line-height: 26px;
      font-weight: 500;
    }
    .message {
      font-size: 14px;
      line-height: 26px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .alert-info-container_normal {
    background: #eef8e9;
    .title {
      color: #52c41a;
    }
  }
  .alert-info-container_abnormal {
    background: #ffecec;
    .title {
      color: #ff4d4f;
    }
  }
  .alert-info-container_scaling_down {
    background: #eaf2fc;
    .title {
      color: #096dd9;
    }
  }
  .alert-info-container_scaling_up {
    background: #fff9f5;
    .title {
      color: #fa6400;
    }
  }
}
</style>
