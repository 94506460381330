<template>
  <div>
    <div style="text-align: right; margin-bottom: 6px;">
      <setting-button
        :columns="columnsAll"
        :default-selected="columnDefaultSelected"
        :show-text="false"
        @selected="updateColumns"
      ></setting-button>
    </div>
    <a-table
      class="custom-table split-table scroll-hidden-table"
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
      row-key="name"
      :scroll="{ x: scrollX }"
      :row-class-name="() => 'pdf-split-flag'"
    >
      <template slot="groups" slot-scope="text">
        <template v-if="text && text.length">
          <span v-for="item in text" :key="item">{{ item }}</span>
        </template>
        <span v-else>-</span>
      </template>
      <template slot="cpu" slot-scope="text">
        <span>{{ text }}</span>
      </template>
      <template slot="cpuUsageAverage" slot-scope="text">
        <span>{{ text }}</span>
      </template>
      <template slot="cpuUsageMax" slot-scope="text">
        <span>{{ text }}</span>
      </template>
      <template slot="memory" slot-scope="text">
        <span>{{ text }}</span>
      </template>
      <template slot="memoryUsageAverage" slot-scope="text">
        <span>{{ text }}</span>
      </template>
      <template slot="memoryUsageMax" slot-scope="text">
        <span>{{ text }}</span>
      </template>
      <template slot="disk" slot-scope="text">
        <span>{{ text }}</span>
      </template>
      <template slot="filesystemUsage" slot-scope="text">
        <span>{{ text }}</span>
      </template>
      <template slot="filesystems" slot-scope="text">
        <template v-if="text && text.length">
          <div v-for="item in text" :key="item.name">
            <a-tooltip>
              <template #title>
                <div style="color: #46b2ff;">{{ item.name }}</div>
                <div style="font-size: 12px;">总容量：{{ item.size }} G</div>
                <div style="font-size: 12px;">已使用：{{ item.used }} G</div>
                <div style="font-size: 12px;">使用率：{{ item.usage }} %</div>
              </template>
              <div style="display: flex; margin-bottom: -8px; font-size: 12px; ">
                <div class="text-overflow" style="color: #727272; flex: 1;">{{ item.name }}：</div>
                <div class="text-overflow" style="min-width: 100px; width: 42%;text-align: right;">
                  <span>{{ item.used }}</span>
                  <span style="color: #727272;">/{{ item.size }}</span>
                </div>
              </div>
              <a-progress :stroke-width="4" :show-info="false" :percent="item.usage" size="small"></a-progress>
            </a-tooltip>
          </div>
        </template>
        <span v-else>-</span>
      </template>
      <template slot="advice" slot-scope="text">
        {{ text || '-' }}
      </template>
    </a-table>
  </div>
</template>

<script>
import SettingButton from '@/components/button/SettingButton.vue'

export default {
  name: 'SourceUsageTable',
  props: {
    dataSource: {
      type: Array,
      required: true
    },
    cpuRange: {
      type: Array
    },
    memoryRange: {
      type: Array
    },
    adviceTitle: {
      type: String,
      default: '优化建议'
    },
    idFlag: {
      type: String
    }
  },
  components: {
    SettingButton
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  data () {
    return {
      columnsAll: [
        {
          dataIndex: 'name',
          title: '资源名称',
          width: 140,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'monitor_address',
          title: 'IP 地址',
          width: 120
        },
        {
          dataIndex: 'groups',
          title: '所属群组',
          width: 120,
          ellipsis: true,
          scopedSlots: {
            customRender: 'groups'
          }
        },
        {
          dataIndex: 'cpu',
          title: 'CPU (核)',
          width: 110,
          align: 'center',
          scopedSlots: {
            customRender: 'cpu'
          },
          sorter: (a, b) => a.cpu - b.cpu
        },
        {
          dataIndex: 'cpu_usage_average',
          title: 'CPU 平均使用率 (%)',
          width: 130,
          align: 'center',
          scopedSlots: {
            customRender: 'cpuUsageAverage'
          },
          sorter: (a, b) => a.cpu_usage_average - b.cpu_usage_average
        },
        {
          dataIndex: 'cpu_usage_max',
          title: 'CPU 最大使用率 (%)',
          width: 130,
          align: 'center',
          scopedSlots: {
            customRender: 'cpuUsageMax'
          },
          sorter: (a, b) => a.cpu_usage_max - b.cpu_usage_max
        },
        {
          dataIndex: 'memory',
          title: '内存 (GB)',
          width: 110,
          align: 'center',
          scopedSlots: {
            customRender: 'memory'
          },
          sorter: (a, b) => a.memory - b.memory
        },
        {
          dataIndex: 'memory_usage_average',
          title: '内存平均使用率 (%)',
          width: 130,
          align: 'center',
          scopedSlots: {
            customRender: 'memoryUsageAverage'
          },
          sorter: (a, b) => a.memory_usage_average - b.memory_usage_average
        },
        {
          dataIndex: 'memory_usage_max',
          title: '内存最大使用率 (%)',
          width: 130,
          align: 'center',
          scopedSlots: {
            customRender: 'memoryUsageMax'
          },
          sorter: (a, b) => a.memory_usage_max - b.memory_usage_max
        },
        {
          dataIndex: 'disk',
          title: '总磁盘 (GB)',
          width: 120,
          align: 'center',
          scopedSlots: {
            customRender: 'disk'
          },
          sorter: (a, b) => a.disk - b.disk
        },
        {
          dataIndex: 'filesystem_usage',
          title: '总文件系统使用率 (%)',
          width: 120,
          align: 'center',
          scopedSlots: {
            customRender: 'filesystemUsage'
          }
        },
        {
          dataIndex: 'filesystems',
          title: '各文件系统使用率',
          width: 240,
          scopedSlots: {
            customRender: 'filesystems'
          }
        },
        {
          dataIndex: 'advice',
          title: '优化建议/异常原因',
          width: 280,
          scopedSlots: {
            customRender: 'advice'
          }
        }
      ],
      columns: [],
      columnDefaultSelected: [
        'name',
        'monitor_address',
        'cpu',
        'cpu_usage_max',
        'memory',
        'memory_usage_max',
        'filesystems',
        'advice'
      ]
    }
  },
  mounted () {
    this.updateColumns(this.columnsAll.filter(
      column => this.columnDefaultSelected.indexOf(column.dataIndex) !== -1
    ))
  },
  methods: {
    updateColumns (columns) {
      this.columns = [...columns]
    }
  }
}
</script>
